import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

const _COMPONENT_NAME: string = "ADMIN_RECOVER_PAGE";

@Component({
  selector: 'app-admin-recover-page',
  templateUrl: './admin-recover-page.component.html',
  styleUrls: ['./admin-recover-page.component.css']
})
export class AdminRecoverPageComponent implements OnInit, OnDestroy {
  email: string = "";

  constructor(
    private _authService: AuthService,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onRecover(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._authService.recover(this.email).then((message: string) => {
      this._alertService.success(message).then(() => {
        this._router.navigateByUrl("/admin");
      });
      this._loaderService.stop(_COMPONENT_NAME);
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }
}
