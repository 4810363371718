<div class="container-fluid bg-secondary py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="text-white mb-3">
                    {{ 'contact_us' | translate }}
                </h1>
                <p class="m-0">
                    {{ 'find_out_what_we_can_do_for_you' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6 mb-3">
                <h2 class="m-0 fs-1-2">
                    {{ 'we_take_your_communications_to_the_next_level' | translate }}:
                </h2>
            </div>
            <div class="col-12 col-lg-6 mb-3">
                <div class="row align-items-center mb-3 mb-lg-0">
                    <div class="col-2 text-center">
                        <p class="m-0 fs-1-2 text-secondary lh-120">
                            1
                        </p>
                    </div>
                    <div class="col-10">
                        <p class="m-0">
                            {{ 'contact_text_1' | translate }}
                        </p>
                    </div>
                </div>
                <div class="row align-items-center mb-3 mb-lg-0">
                    <div class="col-2 text-center">
                        <p class="m-0 fs-1-2 text-secondary lh-120">
                            2
                        </p>
                    </div>
                    <div class="col-10">
                        <p class="m-0">
                            {{ 'contact_text_2' | translate }}
                        </p>
                    </div>
                </div>
                <div class="row align-items-center mb-3 mb-lg-0">
                    <div class="col-2 text-center">
                        <p class="m-0 fs-1-2 text-secondary lh-120">
                            3
                        </p>
                    </div>
                    <div class="col-10">
                        <p class="m-0">
                            {{ 'contact_text_3' | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-7 mb-3">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="form-label">
                            {{ 'full_name' | translate }}
                            <sup>
                                <i class="bi bi-asterisk text-danger" style="font-size: 8px;"></i>
                            </sup>
                        </label>
                        <input type="text" class="form-control bg-gray-2 rounded-0" [(ngModel)]="fullName" />
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="form-label">
                            {{ 'email' | translate }}
                            <sup>
                                <i class="bi bi-asterisk text-danger" style="font-size: 8px;"></i>
                            </sup>
                        </label>
                        <input type="email" class="form-control bg-gray-2 rounded-0" [(ngModel)]="email" />
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-label">
                            {{ 'company' | translate }}
                            <sup>
                                <i class="bi bi-asterisk text-danger" style="font-size: 8px;"></i>
                            </sup>
                        </label>
                        <input type="text" class="form-control bg-gray-2 rounded-0" [(ngModel)]="company" />
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-label">
                            {{ 'message' | translate }}
                            <sup>
                                <i class="bi bi-asterisk text-danger" style="font-size: 8px;"></i>
                            </sup>
                        </label>
                        <textarea class="form-control bg-gray-2 rounded-0" [(ngModel)]="message" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-5 mb-3">
                <label class="form-label">
                    {{ "interest" | translate }}
                </label>
                <div *ngFor="let interest of interests" class="mb-2 d-flex flex-row align-items-center">
                    <input type="checkbox" [(ngModel)]="interest.selected" />
                    <span class="ms-2">
                        {{ interest.name | translate }}
                    </span>
                </div>
            </div>
            <div class="col-12 mb-3">
                <button class="btn btn-lg btn-secondary rounded rounded-5 px-5" (click)="onSend()">
                    {{ 'send' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-dark py-5">
    <div class="container py-5">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 col-lg-auto text-center mb-3">
                <a routerLink="#" class="btn btn-lg btn-primary rounded-5 px-5">
                    {{ 'call_us' | translate }}
                </a>
            </div>
            <div class="col-12 col-lg-auto text-center mb-3">
                <h2 class="m-0 fs-1 text-white">
                    {{ 'you_are_just_one_click_away' | translate }}
                </h2>
            </div>
        </div>
    </div>
</div>
