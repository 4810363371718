import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-admin-private-layout',
  templateUrl: './admin-private-layout.component.html',
  styleUrls: ['./admin-private-layout.component.css']
})
export class AdminPrivateLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('menu')
  private menu?: ElementRef<HTMLElement>;
  
  private offcanvasMenu?: NgbOffcanvasRef = undefined;

  loading: boolean = false;
  
  constructor(
    private _loaderService: LoaderService,
    private _authService: AuthService,
    private _router: Router,
    private _offcanvasService: NgbOffcanvas,
  ) {
    this._loaderService.loadingChanged.subscribe({
      next: (value: boolean) => {
        this.loading = value;
      },
    });

    this._authService.authChanged.subscribe({
      next: () => {
        if (!this._authService.isLogged()) {
          this._router.navigateByUrl("/admin");
        }
      },
    });
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onOpenMenu(): void {
    this.offcanvasMenu = this._offcanvasService.open(this.menu, {
      animation: true,
      backdrop: 'static',
      keyboard: true,
      position: 'end',
      scroll: true,
    });
  }

  onLogout(): void {
    this._authService.logout();
  }

  onCloseMenu(): void {
    this.offcanvasMenu?.dismiss();
  }
}
