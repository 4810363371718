<div class="position-relative">
    <ngb-carousel #carousel [showNavigationIndicators]="false" [showNavigationArrows]="false">
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img src="../../../assets/img/carousel-1.png" alt="" class="w-100" />
            </div>
            <div class="position-absolute top-0 left-0 bottom-0 right-0 h-100 w-100 d-flex flex-column align-items-center justify-content-center text-center">
                <div class="w-75">
                    <h1 class="text-white m-0 text-uppercase fw-bolder">
                        {{ 'we_develop_telecommunications_software_to_connect_the_world' | translate }}
                    </h1>
                    <a routerLink="/" class="btn btn-secondary rounded-5 mt-3 mt-lg-5 fw-bold fs-5 px-3 py-2">
                        {{ 'learn_more' | translate }}
                    </a>
                </div>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img src="../../../assets/img/carousel-1.png" alt="" class="w-100" />
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img src="../../../assets/img/carousel-1.png" alt="" class="w-100" />
            </div>
        </ng-template>
    </ngb-carousel>

    <button class="position-absolute nav-button-left" (click)="onPrevious()">
        <img src="../../../assets/img/flecha_banner.png" alt="" />
    </button>

    <button class="position-absolute nav-button-right" (click)="onNext()">
        <img src="../../../assets/img/flecha_banner.png" alt="" />
    </button>
</div>
