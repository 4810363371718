import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

export interface IServiceItem {
  title: string;
  description: string;
  image: string; 
}

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.css']
})
export class ServiceCardComponent implements OnInit, OnDestroy {
  @ViewChild('carousel')
  carousel?: NgbCarousel;

  @Input()
  color!: "dark" | "primary" | "secondary" | "green";
  @Input()
  title!: string;
  @Input()
  subtitle!: string;
  @Input()
  description!: string;
  @Input()
  subitems!: IServiceItem[]
  @Input()
  url!: string;

  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onNext(): void {
    this.carousel?.next();
  }
}
