import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private componentsLoading: string[] = [];

  loadingChanged: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  start(componentName: string): void {
    if (!this.componentsLoading.includes(componentName)) {
      this.componentsLoading = [
        ...this.componentsLoading,
        componentName,
      ];

      this.loadingChanged.next(true);
    }
  }

  stop(componentName: string): void {
    if (this.componentsLoading.includes(componentName)) {
      let newComponentsLoading: string[] = this.componentsLoading;
      
      let index: number = newComponentsLoading.indexOf(componentName);

      newComponentsLoading.splice(index, 1);

      this.componentsLoading = [
        ...newComponentsLoading,
      ];

      if (newComponentsLoading.length === 0) {
        this.loadingChanged.next(false);
      }
    }
  }
}
