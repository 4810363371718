import { Component } from '@angular/core';

@Component({
  selector: 'app-success-stories-pbx-multitenant-corporate',
  templateUrl: './success-stories-pbx-multitenant-corporate.component.html',
  styleUrls: ['./success-stories-pbx-multitenant-corporate.component.css']
})
export class SuccessStoriesPbxMultitenantCorporateComponent {

}
