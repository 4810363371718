import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-admin-public-layout',
  templateUrl: './admin-public-layout.component.html',
  styleUrls: ['./admin-public-layout.component.css']
})
export class AdminPublicLayoutComponent implements OnInit, OnDestroy {
  loading: boolean = false;

  constructor(
    private _loaderService: LoaderService,
    private _authService: AuthService,
    private _router: Router,
  ) {
    this._loaderService.loadingChanged.subscribe({
      next: (value: boolean) => {
        this.loading = value;
      },
    });

    this._authService.authChanged.subscribe({
      next: () => {
        if (this._authService.isLogged()) {
          this._router.navigateByUrl("/admin/inicio");
        }
      },
    });
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
