<app-carousel></app-carousel>

<div class="bg-white py-5 text-center text-dark">
    <h2 class="text-primary fs-1 mb-3">
        {{ 'our_services' | translate }}
    </h2>
    <p>
        {{ 'we_develop_telecommunications_solutions_to_connect_the_world' | translate }}
    </p>

    <app-service-card color="dark" title="eolos_now_title" subtitle="eolos_now_subtitle" [subitems]="eolosNowSubitems" 
        description="eolos_now_description" url="/services/eolos-now"></app-service-card>

    <app-service-card color="primary" title="hardware_title" subtitle="hardware_subtitle" [subitems]="hardwareSubitems" 
        description="hardware_description" url="/services/hardware"></app-service-card>

    <app-service-card color="green" title="device_connection_title" subtitle="device_connection_subtitle" [subitems]="deviceConnectionSubitems"
        description="device_connection_description" url="/services/device-connections"></app-service-card>

    <app-service-card color="secondary" title="smart_bed_title" subtitle="smart_bed_subtitle" [subitems]="smartBedSubitems"
        description="smart_bed_description" url="/services/smart-bed"></app-service-card>
</div>

<div class="bg-gray py-5 text-center text-dark">
    <h2 class="text-dark fs-1 mb-3">
        {{ 'our_clients' | translate }}
    </h2>
    <p>
        {{ 'these_companies_achieved_amazing_things_with_us' | translate }}
    </p>

    <app-customers-carousel></app-customers-carousel>
</div>

<div class="bg-white py-5 text-center text-dark">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-auto d-none d-lg-block">
                <img src="../../../assets/img/logo1.png" alt="" class="img-fluid" />
            </div>
            <div class="col-12 col-lg">
                <h2 class="text-dark fs-1 mb-3">
                    {{ 'certifications' | translate }}
                </h2>
                <p>
                    {{ 'certifications_text' | translate }}
                </p>
            </div>
            <div class="col-auto d-none d-lg-block">
                <img src="../../../assets/img/logo2.png" alt="" class="img-fluid" />
            </div>

            <div class="col-6 d-lg-none mt-5">
                <img src="../../../assets/img/logo1.png" alt="" class="img-fluid" />
            </div>
            <div class="col-6 d-lg-none mt-5">
                <img src="../../../assets/img/logo2.png" alt="" class="img-fluid" />
            </div>
        </div>
    </div>
</div>

<div class="bg-dark py-5 text-white">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-3 text-center">
                <p class="m-0 fs-4">
                    {{ 'more_than' | translate }}
                </p>
                <p class="m-0 fs-big fw-bold lh-1">
                    800
                </p>
                <p class="m-0">
                    {{ 'happy_clients_around_the_world' | translate }}
                </p>
            </div>
            <div class="col-12 col-lg-3 text-center">
                <p class="m-0 fs-4">
                    {{ 'almost' | translate }}
                </p>
                <p class="m-0 fs-big fw-bold lh-1">
                    20000
                </p>
                <p class="m-0">
                    {{ 'tickets_answered' | translate }}
                </p>
            </div>
            <div class="col-12 col-lg-3 text-center">
                <p class="m-0 fs-4">
                    {{ 'more_than' | translate }}
                </p>
                <p class="m-0 fs-big fw-bold lh-1">
                    10
                </p>
                <p class="m-0">
                    {{ 'millions_code_lines_written' | translate }}
                </p>
            </div>
            <div class="col-12 col-lg-3 text-center">
                <p class="m-0 fs-4">
                    {{ 'more_than' | translate }}
                </p>
                <p class="m-0 fs-big fw-bold lh-1">
                    3
                </p>
                <p class="m-0">
                    {{ 'million_users_connected' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>
