import { Component } from '@angular/core';

@Component({
  selector: 'app-success-stories-cloud-call-center',
  templateUrl: './success-stories-cloud-call-center.component.html',
  styleUrls: ['./success-stories-cloud-call-center.component.css']
})
export class SuccessStoriesCloudCallCenterComponent {

}
