<div class="container-fluid bg-primary py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="text-white mb-3">
                    123Seguro
                </h1>
                <p class="m-0">
                    {{ 'call_center_in_the_cloud' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'the_issue' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    {{ 'cloud_call_center_the_issue_text' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-gray py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'proposal' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    {{ 'cloud_call_center_proposal_text' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'solution' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    {{ 'cloud_call_center_solution_text' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-gray py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'results' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    {{ 'cloud_call_center_results_text' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>
