import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import UserModel from 'src/app/models/user.model';
import { lastValueFrom } from 'rxjs';
import ApiResponseModel from 'src/app/models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private _apiService: ApiService,
  ) {}

  get(): Promise<UserModel[]> {
    return lastValueFrom(this._apiService.get<UserModel[]>(`/admin/user`));
  }

  getById(id: number): Promise<UserModel> {
    return lastValueFrom(this._apiService.get<UserModel>(`/admin/user/${id}`));
  }

  save(user: UserModel): Promise<ApiResponseModel> {
    if (user.id) {
      return lastValueFrom(this._apiService.put<ApiResponseModel>(`/admin/user/${user.id}`, user));
    }
    else {
      return lastValueFrom(this._apiService.post<ApiResponseModel>(`/admin/user`, user));
    }
  }

  delete(user: UserModel): Promise<ApiResponseModel> {
    return lastValueFrom(this._apiService.delete<ApiResponseModel>(`/admin/user/${user.id}`));
  }
}
