import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import languages, { ILanguage } from 'src/assets/i18n/languages';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  constructor(
    private _translateService: TranslateService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  getLanguages(): ILanguage[] {
    return languages;
  }

  setLanguage(language: ILanguage): void {
    this._translateService.use(language.code);
  }

  getCurrentLanguage(): ILanguage | undefined {
    return languages.find((language: ILanguage) => {
      return (language.code === this._translateService.currentLang);
    });
  }

  isActive(urls: string[]): boolean {
    if (this._router.url !== "/") {
      return urls.some((url: string) => {
        return (url !== "/" && this._router.isActive(url, {
          matrixParams: 'ignored',
          queryParams: 'ignored',
          paths: 'subset',
          fragment: 'exact',
        }));
      }); 
    }
    else {
      return urls.includes(this._router.url);
    }
  }
}
