import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

const _UNEXPECTED_ERROR: string = "Ha ocurrido un error inesperado. Por favor, intente nuevamente.";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private _translateService: TranslateService,
  ) {}

  private getHeaders(): { [header: string]: string | string[] } {
    if (this._authService.isLogged()) {
      return {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Language": "es",
        "Authorization": this._authService.getToken(),
      }
    }
    else {
      return {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Language": this._translateService.currentLang,
      };
    }
  }

  private processErrorResponse(error: HttpErrorResponse): string {
    if (error.status === 400) {
      return error.error;
    }
    else {
      return _UNEXPECTED_ERROR;
    }
  }

  get<T>(endpoint: string): Observable<T> {
    return this._httpClient.get<T>(`${environment.API_URL}${endpoint}`, {
      headers: this.getHeaders(),
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        throw this.processErrorResponse(error);
      }),
    );
  }

  post<T>(endpoint: string, data: any = {}): Observable<T> {
    return this._httpClient.post<T>(`${environment.API_URL}${endpoint}`, JSON.stringify(data), {
      headers: this.getHeaders(),
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        throw this.processErrorResponse(error);
      }),
    );
  }

  put<T>(endpoint: string, data: any = {}): Observable<T> {
    return this._httpClient.put<T>(`${environment.API_URL}${endpoint}`, JSON.stringify(data), {
      headers: this.getHeaders(),
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        throw this.processErrorResponse(error);
      }),
    );
  }

  delete<T>(endpoint: string): Observable<T> {
    return this._httpClient.delete<T>(`${environment.API_URL}${endpoint}`, {
      headers: this.getHeaders(),
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        throw this.processErrorResponse(error);
      }),
    );
  }
}
