import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import BlogEntryModel from 'src/app/models/blog-entry.model';
import { lastValueFrom } from 'rxjs';
import ApiResponseModel from 'src/app/models/api-response.model';
import PaginatedModel from '../../models/paginated-model';

@Injectable({
  providedIn: 'root'
})
export class BlogEntryService {
  constructor(
    private _apiService: ApiService,
  ) {}

  get(): Promise<BlogEntryModel[]> {
    return lastValueFrom(this._apiService.get<BlogEntryModel[]>(`/admin/blog-entry`));
  }

  getById(id: number): Promise<BlogEntryModel> {
    return lastValueFrom(this._apiService.get<BlogEntryModel>(`/admin/blog-entry/${id}`));
  }

  save(blogEntry: BlogEntryModel): Promise<ApiResponseModel> {
    if (blogEntry.id) {
      return lastValueFrom(this._apiService.put<ApiResponseModel>(`/admin/blog-entry/${blogEntry.id}`, blogEntry));
    }
    else {
      return lastValueFrom(this._apiService.post<ApiResponseModel>(`/admin/blog-entry`, blogEntry));
    }
  }

  delete(blogEntry: BlogEntryModel): Promise<ApiResponseModel> {
    return lastValueFrom(this._apiService.delete<ApiResponseModel>(`/admin/blog-entry/${blogEntry.id}`));
  }

  getPublic(page: number): Promise<PaginatedModel<BlogEntryModel>> {
    return lastValueFrom(this._apiService.get<PaginatedModel<BlogEntryModel>>(`/blog-entry/get-all/${page}`));
  }

  getByIdPublic(id: number): Promise<BlogEntryModel> {
    return lastValueFrom(this._apiService.get<BlogEntryModel>(`/blog-entry/get-by-id/${id}`));
  }
}
