<div class="container-fluid bg-secondary py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="text-white mb-3">
                    {{ 'about_us' | translate }}
                </h1>
                <p class="m-0">
                    {{ 'your_best_option_for_telecommunications_software_omnichannel_and_collaboration' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'our_history' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    {{ 'our_history_text' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-gray py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'our_offices' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="mb-3">
                    <b>
                        {{ 'united_states' | translate }}
                    </b>
                </p>
                <p class="mb-3">
                    3663 SW 8th. St. ,Suite 210, Miami, FL, 33135
                    <br/>
                    {{ 'phone' | translate }}: +1 (305) 400-2322
                </p>
                <p class="mb-3">
                    <b>
                        {{ 'argentina' | translate }}
                    </b>
                </p>
                <p class="mb-3">
                    Av. Pueyrredón 538, C1190 CABA, Argentina.
                    <br/>
                    {{ 'phone' | translate }}: +54 11 5032 3352
                </p>
            </div>
        </div>
    </div>
</div>
