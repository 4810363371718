import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

const _COMPONENT_NAME: string = "ADMIN_CHANGE_PASSWORD";

@Component({
  selector: 'app-admin-change-password',
  templateUrl: './admin-change-password.component.html',
  styleUrls: ['./admin-change-password.component.css']
})
export class AdminChangePasswordComponent implements OnInit, OnDestroy {
  password: string = "";
  confirmPassword: string = "";

  constructor(
    private _authService: AuthService,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onSave(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._authService.changePassword(this.password, this.confirmPassword).then((response: string) => {
      this.password = "";
      this.confirmPassword = "";
      this._alertService.success(response);
      this._loaderService.stop(_COMPONENT_NAME);
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }
}
