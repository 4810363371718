export interface ILanguage {
    code: string;
    name: string;
    flag_code: string;
};

let languages : ILanguage[] = [
    {
        code: "en",
        name: "English",
        flag_code: "gb",
    },
    {
        code: "es",
        name: "Español",
        flag_code: "es",
    },
];

export default languages;
