import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import BlogEntryLanguageModel from 'src/app/models/blog-entry-language.model';
import BlogEntryModel from 'src/app/models/blog-entry.model';
import PaginatedModel from 'src/app/models/paginated-model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { BlogEntryService } from 'src/app/services/blog-entry/blog-entry.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

const _COMPONENT_NAME: string = "BLOG_PAGE";

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.css']
})
export class BlogPageComponent implements OnInit, OnDestroy {
  private page: number = 1;
  
  blogEntries?: PaginatedModel<BlogEntryModel> = undefined;

  constructor(
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    private _blogEntryService: BlogEntryService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._blogEntryService.getPublic(this.page).then((blogEntries: PaginatedModel<BlogEntryModel>) => {
      this.blogEntries = blogEntries;
      this._loaderService.stop(_COMPONENT_NAME);
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }

  ngOnDestroy(): void {
    
  }

  getBlogEntryTitle(blogEntry: BlogEntryModel): string {
    return blogEntry.blog_entry_languages.find((bel: BlogEntryLanguageModel) => {
      return (bel.language.code === this._translateService.currentLang);
    })?.title || "";
  }
}
