<div class="row align-items-center">
    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-2">
        <div class="d-grid gap-2">
            <a routerLink="/admin/usuarios" class="btn btn-primary">
                <i class="bi bi-people-fill" style="font-size: 3rem;"></i>
                <br/>
                Usuarios
            </a>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-2">
        <div class="d-grid gap-2">
            <a routerLink="/admin/entradas-blog" class="btn btn-primary">
                <i class="bi bi-file-text-fill" style="font-size: 3rem;"></i>
                <br/>
                Entradas de blog
            </a>
        </div>
    </div>

    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-2">
        <div class="d-grid gap-2">
            <a routerLink="/admin/cambiar-contrasena" class="btn btn-primary">
                <i class="bi bi-person-fill-gear" style="font-size: 3rem;"></i>
                <br/>
                Cambiar contraseña
            </a>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-2">
        <div class="d-grid gap-2">
            <button class="btn btn-danger" (click)="onLogout()">
                <i class="bi bi-power" style="font-size: 3rem;"></i>
                <br/>
                Cerrar sesión
            </button>
        </div>
    </div>
</div>
