<div class="container-fluid bg-primary py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="text-white mb-3">
                    {{ 'device_connection_title' | translate }}
                </h1>
                <p class="m-0">
                    {{ 'device_connection_subtitle' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'solution' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud. Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-gray py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'deployment' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud. Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'features' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud. Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-gray py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'value_added_benefits' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud. Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1">
                    {{ 'clients' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7">
                <p class="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud. Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-dark py-5">
    <div class="container py-5">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5">
                <h2 class="m-0 fs-1 text-white">
                    {{ 'learn_more' | translate }}
                </h2>
            </div>
            <div class="col-12 col-lg-7 text-center">
                <a routerLink="/contact" class="btn btn-primary rounded-5 px-3">
                    {{ 'contact_us' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
