import { Component } from '@angular/core';

@Component({
  selector: 'app-success-stories-integral-telephony-solution',
  templateUrl: './success-stories-integral-telephony-solution.component.html',
  styleUrls: ['./success-stories-integral-telephony-solution.component.css']
})
export class SuccessStoriesIntegralTelephonySolutionComponent {

}
