import { Component, OnDestroy, OnInit } from '@angular/core';
import UserModel from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { UserService } from 'src/app/services/user/user.service';

const _COMPONENT_NAME: string = "ADMIN_USERS_PAGE";

@Component({
  selector: 'app-admin-users-page',
  templateUrl: './admin-users-page.component.html',
  styleUrls: ['./admin-users-page.component.css']
})
export class AdminUsersPageComponent implements OnInit, OnDestroy {
  users: UserModel[] = [];
  filter: string = "";

  constructor(
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    private _userService: UserService,
  ) {}

  ngOnInit(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._userService.get().then((users: UserModel[]) => {
      this.users = users;
      this._loaderService.stop(_COMPONENT_NAME);
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }

  ngOnDestroy(): void {
    
  }

  getFilteredUsers(): UserModel[] {
    return this.users.filter((user: UserModel) => {
      return (
        user.name.toLowerCase().includes(this.filter.toLowerCase()) ||
        user.last_name.toLowerCase().includes(this.filter.toLowerCase()) ||
        user.email.toLowerCase().includes(this.filter.toLowerCase())
      );
    });
  }
}
