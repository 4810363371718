import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorConfig } from '@ckeditor/ckeditor5-core';
import * as moment from 'moment';
import ApiResponseModel from 'src/app/models/api-response.model';
import BlogEntryLanguageModel from 'src/app/models/blog-entry-language.model';
import BlogEntryModel from 'src/app/models/blog-entry.model';
import LanguageModel from 'src/app/models/language.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { BlogEntryService } from 'src/app/services/blog-entry/blog-entry.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { environment } from 'src/environments/environment';

const _COMPONENT_NAME: string = "ADMIN_BLOG_ENTRY_PAGE";

@Component({
  selector: 'app-admin-blog-entry-page',
  templateUrl: './admin-blog-entry-page.component.html',
  styleUrls: ['./admin-blog-entry-page.component.css']
})
export class AdminBlogEntryPageComponent implements OnInit, OnDestroy {
  public Editor = ClassicEditor;

  editorConfig: EditorConfig = {
    extraPlugins: [],
    ckfinder: {
      options: {
        chooseFiles: false,
        language: "es",
      },
      uploadUrl: `${environment.API_URL}/admin/image/upload`,
    },
  };
  
  languages: LanguageModel[] = [];
  blogEntry: BlogEntryModel = {
    id: 0,
    date: moment().utc(true).format("YYYY-MM-DD"),
    blog_entry_languages: [],
  };

  constructor(
    private _languageService: LanguageService,
    private _blogEntryService: BlogEntryService,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._languageService.get().then((languages: LanguageModel[]) => {
      this.languages = languages;
      
      let id: string | null = this._activatedRoute.snapshot.paramMap.get('id');
      if (id) {
        this._blogEntryService.getById(parseInt(id)).then((blogEntry: BlogEntryModel) => {
          this.blogEntry = {
            ...blogEntry,
            blog_entry_languages: [
              ...blogEntry.blog_entry_languages,
              ...languages.filter((language: LanguageModel) => {
                return !blogEntry.blog_entry_languages.some((blogEntryLanguage: BlogEntryLanguageModel) => {
                  return (blogEntryLanguage.language_id === language.id);
                });
              }).map((language: LanguageModel) => {
                return {
                  id: 0,
                  blog_entry_id: blogEntry.id,
                  language_id: language.id,
                  language: language,
                  title: "",
                  content: "",
                };
              }),
            ],
          };
          this._loaderService.stop(_COMPONENT_NAME);
        }, (error: string) => {
          this._alertService.error(error);
          this._loaderService.stop(_COMPONENT_NAME);
        });
      }
      else {
        this.blogEntry = {
          ...this.blogEntry,
          blog_entry_languages: [
            ...languages.map((language: LanguageModel) => {
              return {
                id: 0,
                blog_entry_id: 0,
                language_id: language.id,
                language: language,
                title: "",
                content: "",
              };
            }),
          ],
        };
        this._loaderService.stop(_COMPONENT_NAME);
      }
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }

  ngOnDestroy(): void {
    
  }

  onSave(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._blogEntryService.save(this.blogEntry).then((response: ApiResponseModel) => {
      this._alertService.success(response.message).then(() => {
        this._router.navigateByUrl("/admin/entradas-blog");
      });
      this._loaderService.stop(_COMPONENT_NAME);
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }

  onDelete(): void {
    this._alertService.confirm(`¿Confirma que desea eliminar la entrada de blog ${this.getBlogEntryTitle()}?`).then((response: boolean) => {
      if (response) {
        this._loaderService.start(_COMPONENT_NAME);
        this._blogEntryService.delete(this.blogEntry).then((response: ApiResponseModel) => {
          this._alertService.success(response.message).then(() => {
            this._router.navigateByUrl("/admin/entradas-blog");
          });
          this._loaderService.stop(_COMPONENT_NAME);
        }, (error: string) => {
          this._alertService.error(error);
          this._loaderService.stop(_COMPONENT_NAME);
        });
      }
    })
  }

  private getBlogEntryTitle(): string {
    return this.blogEntry.blog_entry_languages.find((blogEntryLanguage: BlogEntryLanguageModel) => {
      return (blogEntryLanguage.language.code === "es");
    })?.title || "";
  }
}
