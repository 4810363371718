import { Component } from '@angular/core';

@Component({
  selector: 'app-success-stories-contact-center-bot-whatsapp',
  templateUrl: './success-stories-contact-center-bot-whatsapp.component.html',
  styleUrls: ['./success-stories-contact-center-bot-whatsapp.component.css']
})
export class SuccessStoriesContactCenterBotWhatsappComponent {

}
