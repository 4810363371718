<div class="container-fluid mt-5" *ngIf="imagePercentage">
    <div class="row align-items-center">
        <div class="col-auto">
            <button class="nav-button-left" (click)="onPrevious()">
                <img src="../../../assets/img/f1.png" alt="" />
            </button>
        </div>
        <div class="col">
            <ng-image-slider [images]="images" [infinite]="true" [showArrow]="false" [autoSlide]="false" [manageImageRatio]="true" [imageSize]="{
                width: imagePercentage + '%',
                space: 0,
            }" slideImage="1" #carousel></ng-image-slider>
        </div>
        <div class="col-auto">
            <button class="nav-button-right" (click)="onNext()">
                <img src="../../../assets/img/f1.png" alt="" />
            </button>
        </div>
    </div>
</div>

