<div class="container-fluid mt-5 px-0">
    <div class="row align-items-stretch mx-0 ps-lg-5">
        <div class="col-12 col-lg-4 py-3 px-lg-5 text-start">
            <h2 class="m-0">
                {{ title | translate }}
            </h2>
            <h4 class="m-0 text-gray">
                {{ subtitle | translate }}
            </h4>
            <p class="my-4 text-gray">
                {{ description | translate }}
            </p>
            <a [routerLink]="url" class="btn rounded-5 px-3" 
                [ngClass]="{'btn-primary': (color === 'primary'), 'btn-dark': (color === 'dark'), 'btn-secondary': (color === 'secondary'), 'btn-green': (color === 'green')}">
                {{ 'view_all_the_features' | translate }}
            </a>
        </div>
        <div class="col-10 col-lg d-flex flex-column justify-content-center text-white py-3 rounded-start-50 pe-0" 
            [ngClass]="{'bg-primary': (color === 'primary'), 'bg-dark': (color === 'dark'), 'bg-secondary': (color === 'secondary'), 'bg-green': (color === 'green')}">
            <ngb-carousel #carousel [showNavigationIndicators]="false" [showNavigationArrows]="false">
                <ng-template ngbSlide *ngFor="let subitem of subitems">
                    <div class="row align-items-center mx-0">
                        <div class="col text-start">
                            <h3 class="m-0">
                                {{ subitem.title | translate }}
                            </h3>
                            <p class="mt-3">
                                {{ subitem.description | translate }}
                            </p>
                        </div>
                        <div class="col">
                            <img [src]="subitem.image" [alt]="subitem.title" class="img-fluid" />
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
        <div class="col-auto d-flex flex-column justify-content-center"
            [ngClass]="{'bg-primary': (color === 'primary'), 'bg-dark': (color === 'dark'), 'bg-secondary': (color === 'secondary'), 'bg-green': (color === 'green')}">
            <button class="nav-button-right" (click)="onNext()">
                <img src="../../../assets/img/f1.png" alt="" *ngIf="color === 'dark'" />
                <img src="../../../assets/img/f2.png" alt="" *ngIf="color === 'primary'" />
                <img src="../../../assets/img/f3.png" alt="" *ngIf="color === 'green'" />
                <img src="../../../assets/img/f4.png" alt="" *ngIf="color === 'secondary'" />
            </button>
        </div>
    </div>
</div>
