<app-navbar></app-navbar>

<div class="container-fluid p-0">
    <div class="row m-0">
        <div class="col-12 p-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<app-footer></app-footer>

<app-loader [visible]="loading"></app-loader>
