import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

const _COMPONENT_NAME: string = "ADMIN_RESET_PAGE";

@Component({
  selector: 'app-admin-reset-page',
  templateUrl: './admin-reset-page.component.html',
  styleUrls: ['./admin-reset-page.component.css']
})
export class AdminResetPageComponent implements OnInit, OnDestroy {
  password: string = "";
  confirmPassword: string = "";
  token: string = "";

  constructor(
    private _authService: AuthService,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    let token: string | null = this._activatedRoute.snapshot.paramMap.get('token');
    if (token) {
      this.token = token;
    }
    else {
      this._router.navigateByUrl("/admin");
    }
  }

  ngOnDestroy(): void {
    
  }

  onReset(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._authService.reset(this.password, this.confirmPassword, this.token).then((message: string) => {
      this._alertService.success(message).then(() => {
        this._router.navigateByUrl("/admin");
      });
      this._loaderService.stop(_COMPONENT_NAME);
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }
}
