<div class="container-fluid bg-secondary py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="text-white mb-3">
                    {{ 'blog' | translate }}
                </h1>
                <p class="m-0">
                    {{ 'learn_about_telecommunications_software_and_productivity_tools' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngFor="let blogEntry of blogEntries?.data; let i = index" class="container-fluid py-5" 
    [ngClass]="{ 'bg-white': (i % 2 === 0), 'bg-gray': (i % 2 !== 0) }">
    <div class="container">
        <a [routerLink]="['/blog', blogEntry.id, getBlogEntryTitle(blogEntry)]" class="row align-items-center text-dark text-decoration-none">
            <div class="col-12 col-lg-5">
                <p class="m-0">
                    {{ blogEntry.date | date:'dd-MM-yyyy' }}
                </p>
            </div>
            <div class="col-12 col-lg-7 text-center">
                <h2 class="m-0 fs-1">
                    {{ getBlogEntryTitle(blogEntry) }}
                </h2>
            </div>
        </a>
    </div>
</div>
