import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgImageSliderComponent } from 'ng-image-slider';

@Component({
  selector: 'app-customers-carousel',
  templateUrl: './customers-carousel.component.html',
  styleUrls: ['./customers-carousel.component.css']
})
export class CustomersCarouselComponent implements OnInit, OnDestroy {
  @ViewChild('carousel')
  carousel?: NgImageSliderComponent;

  imagePercentage?: number = undefined;
  images: object[] = [
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
    {
      image: '../../../assets/img/carousel-1.png',
      thumbImage: '../../../assets/img/carousel-1.png',
      alt: "",
    },
  ];
  
  constructor() {
    if (window.innerWidth >= 992) {
      this.imagePercentage = 20;
    }
    else {
      this.imagePercentage = 33.33;
    }
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onPrevious(): void {
    this.carousel?.prev();
  }

  onNext(): void {
    this.carousel?.next();
  }

  getImagePercentage(): number {
    return 20;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if ((event.target as Window).innerWidth >= 992) {
      this.imagePercentage = 20;
    }
    else {
      this.imagePercentage = 33.33;
    }
  }
}
