import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import LanguageModel from 'src/app/models/language.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    private _apiService: ApiService,
  ) {}

  get(): Promise<LanguageModel[]> {
    return lastValueFrom(this._apiService.get<LanguageModel[]>(`/admin/language`));
  }

  getById(id: number): Promise<LanguageModel> {
    return lastValueFrom(this._apiService.get<LanguageModel>(`/admin/language/${id}`));
  }
}
