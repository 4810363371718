<div class="container-fluid bg-gray py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5 mb-3">
                <h1 class="mb-3">
                    {{ 'eolos_now_title' | translate }}
                </h1>
                <p class="m-0">
                    {{ 'eolos_now_description' | translate }}
                </p>
            </div>
            <div class="col-12 col-lg-7 mb-3">
                <img src="../../../assets/img/carousel-1.png" alt="" class="img-fluid border border-5 border-white rounded-5" />
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1 class="mb-5 text-center">
                    {{ 'the_modules' | translate }}
                </h1>

                <ul ngbNav #nav="ngbNav" class="nav-tabs justify-content-between border-0 mb-5">
                    <li ngbNavItem class="col-6 col-lg mb-3 px-3">
                        <button ngbNavLink class="w-100">
                            {{ 'eolos_now_messaging_module_title' | translate }}
                        </button>
                        <ng-template ngbNavContent>
                            <h2 class="mb-3">
                                {{ 'eolos_now_messaging_module_title' | translate }}
                            </h2>
                            <p class="m-0">
                                {{ 'eolos_now_messaging_module_description' | translate }}
                            </p>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="col-6 col-lg mb-3 px-3">
                        <button ngbNavLink class="w-100">
                            {{ 'eolos_now_telephony_module_title' | translate }}
                        </button>
                        <ng-template ngbNavContent>
                            <h2 class="mb-3">
                                {{ 'eolos_now_telephony_module_title' | translate }}
                            </h2>
                            <p class="m-0">
                                {{ 'eolos_now_telephony_module_description' | translate }}
                            </p>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="col-6 col-lg mb-3 px-3">
                        <button ngbNavLink class="w-100">
                            {{ 'eolos_now_contact_center_module_title' | translate }}
                        </button>
                        <ng-template ngbNavContent>
                            <h2 class="mb-3">
                                {{ 'eolos_now_contact_center_module_title' | translate }}
                            </h2>
                            <p class="m-0">
                                {{ 'eolos_now_contact_center_module_description' | translate }}
                            </p>
                        </ng-template>
                    </li>
                    <li ngbNavItem class="col-6 col-lg  mb-3 px-3">
                        <button ngbNavLink class="w-100">
                            {{ 'eolos_now_integrations_module_title' | translate }}
                        </button>
                        <ng-template ngbNavContent>
                            <h2 class="mb-3">
                                {{ 'eolos_now_integrations_module_title' | translate }}
                            </h2>
                            <p class="m-0">
                                {{ 'eolos_now_integrations_module_description' | translate }}
                            </p>
                        </ng-template>
                    </li>
                </ul>

                <div class="row">
                    <div class="col-12 col-lg-6 pe-lg-5 mb-3">
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                    <div class="col-12 col-lg-6 ps-lg-5 mb-3">
                        <h2 class="mb-3">
                            {{ 'Otro servicio' }}
                        </h2>
                        <p class="m-0">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                            <br/>
                            Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui ocia deserunt
                            mollit anim id est laborum.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5 bg-white-dark">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-4 mb-3">
                <app-info-card color="primary" description="eolos_now_info_1"></app-info-card>
            </div>
            <div class="col-12 col-lg-4 mb-3">
                <app-info-card color="green" description="eolos_now_info_2"></app-info-card>
            </div>
            <div class="col-12 col-lg-4 mb-3">
                <app-info-card color="secondary" description="eolos_now_info_3"></app-info-card>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5 bg-dark text-white">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-5 mb-3">
                <h1 class="mb-3">
                    {{ 'features' | translate }}
                </h1>
                <p class="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                    <br/>
                    <br/>
                    Incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud .
                    <br/>
                    <br/>
                    Exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
            </div>
            <div class="col-12 col-lg-7 mb-3">
                <img src="../../../assets/img/carousel-1.png" alt="" class="img-fluid" />
            </div>
        </div>
    </div>
</div>
