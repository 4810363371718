import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminPublicLayoutComponent } from './layouts/admin-public-layout/admin-public-layout.component';
import { AdminLoginPageComponent } from './pages/admin/admin-login-page/admin-login-page.component';
import { AdminIndexPageComponent } from './pages/admin/admin-index-page/admin-index-page.component';
import { AdminRecoverPageComponent } from './pages/admin/admin-recover-page/admin-recover-page.component';
import { AdminResetPageComponent } from './pages/admin/admin-reset-page/admin-reset-page.component';
import { AdminPrivateLayoutComponent } from './layouts/admin-private-layout/admin-private-layout.component';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { CustomersCarouselComponent } from './components/customers-carousel/customers-carousel.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { AdminUsersPageComponent } from './pages/admin/admin-users-page/admin-users-page.component';
import { AdminUserPageComponent } from './pages/admin/admin-user-page/admin-user-page.component';
import { AdminChangePasswordComponent } from './pages/admin/admin-change-password/admin-change-password.component';
import { AdminBlogEntriesPageComponent } from './pages/admin/admin-blog-entries-page/admin-blog-entries-page.component';
import { AdminBlogEntryPageComponent } from './pages/admin/admin-blog-entry-page/admin-blog-entry-page.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ServicesEolosNowPageComponent } from './pages/services-eolos-now-page/services-eolos-now-page.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { ServicesHardwarePageComponent } from './pages/services-hardware-page/services-hardware-page.component';
import { ServicesDeviceConnectionsPageComponent } from './pages/services-device-connections-page/services-device-connections-page.component';
import { ServicesSmartBedPageComponent } from './pages/services-smart-bed-page/services-smart-bed-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { BlogEntryPageComponent } from './pages/blog-entry-page/blog-entry-page.component';
import { SuccessStoriesCloudCallCenterComponent } from './pages/success-stories-cloud-call-center/success-stories-cloud-call-center.component';
import { SuccessStoriesPbxMultitenantCorporateComponent } from './pages/success-stories-pbx-multitenant-corporate/success-stories-pbx-multitenant-corporate.component';
import { SuccessStoriesIntegralTelephonySolutionComponent } from './pages/success-stories-integral-telephony-solution/success-stories-integral-telephony-solution.component';
import { SuccessStoriesContactCenterBotWhatsappComponent } from './pages/success-stories-contact-center-bot-whatsapp/success-stories-contact-center-bot-whatsapp.component';
import { QualityPoliciesPageComponent } from './pages/quality-policies-page/quality-policies-page.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AdminPublicLayoutComponent,
    AdminPrivateLayoutComponent,
    AdminLoginPageComponent,
    AdminIndexPageComponent,
    AdminRecoverPageComponent,
    AdminResetPageComponent,
    LoaderComponent,
    PublicLayoutComponent,
    IndexPageComponent,
    NavbarComponent,
    FooterComponent,
    CarouselComponent,
    ServiceCardComponent,
    CustomersCarouselComponent,
    AdminUsersPageComponent,
    AdminUserPageComponent,
    AdminChangePasswordComponent,
    AdminBlogEntriesPageComponent,
    AdminBlogEntryPageComponent,
    ServicesEolosNowPageComponent,
    InfoCardComponent,
    ServicesHardwarePageComponent,
    ServicesDeviceConnectionsPageComponent,
    ServicesSmartBedPageComponent,
    AboutUsPageComponent,
    BlogPageComponent,
    ContactPageComponent,
    BlogEntryPageComponent,
    SuccessStoriesCloudCallCenterComponent,
    SuccessStoriesPbxMultitenantCorporateComponent,
    SuccessStoriesIntegralTelephonySolutionComponent,
    SuccessStoriesContactCenterBotWhatsappComponent,
    QualityPoliciesPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    NgImageSliderModule,
    CKEditorModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [
          HttpClient,
        ],
      },
    }),
  ],
  providers: [],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
