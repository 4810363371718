<div class="container-fluid bg-secondary py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="text-white mb-3">
                    {{ blogEntryTitle }}
                </h1>
                <p class="m-0">
                    {{ blogEntry?.date | date:'dd-MM-yyyy' }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5 bg-white">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12" [innerHTML]="blogEntryContent"></div>
        </div>
    </div>
</div>

