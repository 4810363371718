import { Component, OnDestroy, OnInit } from '@angular/core';
import BlogEntryLanguageModel from 'src/app/models/blog-entry-language.model';
import BlogEntryModel from 'src/app/models/blog-entry.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { BlogEntryService } from 'src/app/services/blog-entry/blog-entry.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

const _COMPONENT_NAME: string = "ADMIN_BLOG_ENTRIES_PAGE";

@Component({
  selector: 'app-admin-blog-entries-page',
  templateUrl: './admin-blog-entries-page.component.html',
  styleUrls: ['./admin-blog-entries-page.component.css']
})
export class AdminBlogEntriesPageComponent implements OnInit, OnDestroy {
  blogEntries: BlogEntryModel[] = [];
  filter: string = "";

  constructor(
    private _blogEntryService: BlogEntryService,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._blogEntryService.get().then((blogEntries: BlogEntryModel[]) => {
      this.blogEntries = blogEntries;
      this._loaderService.stop(_COMPONENT_NAME);
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }

  ngOnDestroy(): void {
    
  }

  getFilteredBlogEntries(): BlogEntryModel[] {
    return this.blogEntries.filter((blogEntry: BlogEntryModel) => {
      return (
        this.getBlogEntryTitle(blogEntry).toLowerCase().includes(this.filter.toLowerCase())
      );
    });
  }

  getBlogEntryTitle(blogEntry: BlogEntryModel): string {
    return blogEntry.blog_entry_languages.find((blogEntryLanguage: BlogEntryLanguageModel) => {
      return (blogEntryLanguage.language.code === "es");
    })?.title || "";
  }
}
