import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-admin-index-page',
  templateUrl: './admin-index-page.component.html',
  styleUrls: ['./admin-index-page.component.css']
})
export class AdminIndexPageComponent implements OnInit, OnDestroy {
  constructor(
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onLogout(): void {
    this._authService.logout();
  }
}
