import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import BlogEntryLanguageModel from 'src/app/models/blog-entry-language.model';
import BlogEntryModel from 'src/app/models/blog-entry.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { BlogEntryService } from 'src/app/services/blog-entry/blog-entry.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

const _COMPONENT_NAME: string = "BLOG_ENTRY_PAGE";

@Component({
  selector: 'app-blog-entry-page',
  templateUrl: './blog-entry-page.component.html',
  styleUrls: ['./blog-entry-page.component.css']
})
export class BlogEntryPageComponent implements OnInit, OnDestroy {
  blogEntry?: BlogEntryModel = undefined;

  get blogEntryTitle(): string {
    return this.blogEntry?.blog_entry_languages.find((bel: BlogEntryLanguageModel) => {
      return (bel.language.code === this._translateService.currentLang);
    })?.title || "";
  }

  get blogEntryContent(): string {
    return this.blogEntry?.blog_entry_languages.find((bel: BlogEntryLanguageModel) => {
      return (bel.language.code === this._translateService.currentLang);
    })?.content || "";
  }

  constructor(
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    private _blogEntryService: BlogEntryService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this._loaderService.start(_COMPONENT_NAME);
    let id: string | null = this._activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      this._blogEntryService.getByIdPublic(parseInt(id)).then((blogEntry: BlogEntryModel) => {
        this.blogEntry = blogEntry;
        this._loaderService.stop(_COMPONENT_NAME);
      }, (error: string) => {
        this._alertService.error(error);
        this._loaderService.stop(_COMPONENT_NAME);
      });
    }
    else {
      this._router.navigateByUrl("/blog");
    }
  }

  ngOnDestroy(): void {
    
  }
}
