import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPublicLayoutComponent } from './layouts/admin-public-layout/admin-public-layout.component';
import { AdminPublicGuard } from './guards/admin-public/admin-public.guard';
import { AdminPrivateGuard } from './guards/admin-private/admin-private.guard';
import { AdminPrivateLayoutComponent } from './layouts/admin-private-layout/admin-private-layout.component';
import { AdminLoginPageComponent } from './pages/admin/admin-login-page/admin-login-page.component';
import { AdminIndexPageComponent } from './pages/admin/admin-index-page/admin-index-page.component';
import { AdminRecoverPageComponent } from './pages/admin/admin-recover-page/admin-recover-page.component';
import { AdminResetPageComponent } from './pages/admin/admin-reset-page/admin-reset-page.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { AdminUsersPageComponent } from './pages/admin/admin-users-page/admin-users-page.component';
import { AdminUserPageComponent } from './pages/admin/admin-user-page/admin-user-page.component';
import { AdminChangePasswordComponent } from './pages/admin/admin-change-password/admin-change-password.component';
import { AdminBlogEntriesPageComponent } from './pages/admin/admin-blog-entries-page/admin-blog-entries-page.component';
import { AdminBlogEntryPageComponent } from './pages/admin/admin-blog-entry-page/admin-blog-entry-page.component';
import { ServicesEolosNowPageComponent } from './pages/services-eolos-now-page/services-eolos-now-page.component';
import { ServicesHardwarePageComponent } from './pages/services-hardware-page/services-hardware-page.component';
import { ServicesDeviceConnectionsPageComponent } from './pages/services-device-connections-page/services-device-connections-page.component';
import { ServicesSmartBedPageComponent } from './pages/services-smart-bed-page/services-smart-bed-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { BlogEntryPageComponent } from './pages/blog-entry-page/blog-entry-page.component';
import { SuccessStoriesCloudCallCenterComponent } from './pages/success-stories-cloud-call-center/success-stories-cloud-call-center.component';
import { SuccessStoriesPbxMultitenantCorporateComponent } from './pages/success-stories-pbx-multitenant-corporate/success-stories-pbx-multitenant-corporate.component';
import { SuccessStoriesIntegralTelephonySolutionComponent } from './pages/success-stories-integral-telephony-solution/success-stories-integral-telephony-solution.component';
import { SuccessStoriesContactCenterBotWhatsappComponent } from './pages/success-stories-contact-center-bot-whatsapp/success-stories-contact-center-bot-whatsapp.component';
import { QualityPoliciesPageComponent } from './pages/quality-policies-page/quality-policies-page.component';

const routes: Routes = [
  // Public routes
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      {
        path: "",
        component: IndexPageComponent,
      },
      {
        path: "services",
        children: [
          {
            path: "eolos-now",
            component: ServicesEolosNowPageComponent,
          },
          {
            path: "hardware",
            component: ServicesHardwarePageComponent,
          },
          {
            path: "device-connections",
            component: ServicesDeviceConnectionsPageComponent,
          },
          {
            path: "smart-bed",
            component: ServicesSmartBedPageComponent,
          },
        ],
      },
      {
        path: "about-us",
        component: AboutUsPageComponent,
      },
      {
        path: "success-stories",
        children: [
          {
            path: "cloud-call-center",
            component: SuccessStoriesCloudCallCenterComponent,
          },
          {
            path: "pbx-multitenant-corporate",
            component: SuccessStoriesPbxMultitenantCorporateComponent,
          },
          {
            path: "integral-telephony-solution",
            component: SuccessStoriesIntegralTelephonySolutionComponent,
          },
          {
            path: "contact-center-bot-whatsapp",
            component: SuccessStoriesContactCenterBotWhatsappComponent,
          },
        ],
      },
      {
        path: "blog",
        component: BlogPageComponent,
      },
      {
        path: "blog/:id/:title",
        component: BlogEntryPageComponent,
      },
      {
        path: "contact",
        component: ContactPageComponent,
      },
      {
        path: "quality-policies",
        component: QualityPoliciesPageComponent,
      },
    ],
  },
  // Admin public routes
  {
    path: "admin", 
    component: AdminPublicLayoutComponent,
    canActivate: [
      AdminPublicGuard,
    ],
    children: [
      {
        path: "",
        component: AdminLoginPageComponent,
      },
      {
        path: "recuperar",
        component: AdminRecoverPageComponent,
      },
      {
        path: "restablecer/:token",
        component: AdminResetPageComponent,
      },
    ],
  },
  // Admin private routes
  {
    path: "admin", 
    component: AdminPrivateLayoutComponent,
    canActivate: [
      AdminPrivateGuard,
    ],
    children: [
      {
        path: "inicio",
        component: AdminIndexPageComponent,
      },
      {
        path: "cambiar-contrasena",
        component: AdminChangePasswordComponent,
      },
      {
        path: "usuarios",
        component: AdminUsersPageComponent,
      },
      {
        path: "usuario",
        component: AdminUserPageComponent,
      },
      {
        path: "usuario/:id",
        component: AdminUserPageComponent,
      },
      {
        path: "entradas-blog",
        component: AdminBlogEntriesPageComponent,
      },
      {
        path: "entrada-blog",
        component: AdminBlogEntryPageComponent,
      },
      {
        path: "entrada-blog/:id",
        component: AdminBlogEntryPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
