import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor() {}

  error(message: string): Promise<void> {
    return new Promise((resolve) => {
      Swal.fire({
        title: undefined,
        titleText: "ERROR",
        text: message,
        html: undefined,
        icon: "error",
        iconColor: "#DC3545",
        iconHtml: "<i class='bi bi-exclamation-triangle'></i>",
        footer: undefined,
        template: undefined,
        backdrop: true,
        toast: false,
        target: "body",
        width: undefined,
        padding: undefined,
        color: "#DC3545",
        background: "#FFFFFF",
        position: "center",
        grow: false,
        animation: true,
        showClass: undefined,
        hideClass: undefined,
        customClass: {
          container: undefined,
          popup: 'p-3',
          title: 'text-uppercase',
          closeButton: undefined,
          icon: 'my-0',
          image: undefined,
          htmlContainer: undefined,
          input: undefined,
          inputLabel: undefined,
          validationMessage: undefined,
          actions: 'w-100',
          confirmButton: 'btn btn-danger text-uppercase w-100',
          denyButton: undefined,
          cancelButton: undefined,
          loader: undefined,
          footer: undefined,
          timerProgressBar: undefined,
        },
        timer: undefined,
        timerProgressBar: false,
        heightAuto: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: true,
        stopKeydownPropagation: true,
        keydownListenerCapture: false,
        showConfirmButton: true,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'OK',
        denyButtonText: undefined,
        cancelButtonText: undefined,
        confirmButtonColor: undefined,
        denyButtonColor: undefined,
        cancelButtonColor: undefined,
        confirmButtonAriaLabel: undefined,
        denyButtonAriaLabel: undefined,
        cancelButtonAriaLabel: undefined,
        buttonsStyling: false,
        reverseButtons: false,
        focusConfirm: true,
        focusDeny: false,
        focusCancel: false,
        returnFocus: true,
        showCloseButton: false,
        closeButtonHtml: undefined,
        closeButtonAriaLabel: undefined,
        loaderHtml: undefined,
        showLoaderOnConfirm: false,
        showLoaderOnDeny: false,
        preConfirm: undefined,
        preDeny: undefined,
        imageUrl: undefined,
        imageWidth: undefined,
        imageHeight: undefined,
        imageAlt: undefined,
        inputLabel: undefined,
        inputPlaceholder: undefined,
        inputValue: undefined,
        inputOptions: undefined,
        inputAutoFocus: undefined,
        inputAutoTrim: undefined,
        inputAttributes: undefined,
        returnInputValueOnDeny: undefined,
        validationMessage: undefined,
        progressSteps: undefined,
        currentProgressStep: undefined,
        progressStepsDistance: undefined,
        willOpen: undefined,
        didOpen: undefined,
        didRender: undefined,
        willClose: undefined,
        didClose: undefined,
        didDestroy: undefined,
        scrollbarPadding: undefined,
      }).then(() => {
        resolve();
      });
    });
  }

  success(message: string): Promise<void> {
    return new Promise((resolve) => {
      Swal.fire({
        title: undefined,
        titleText: "OK",
        text: message,
        html: undefined,
        icon: "success",
        iconColor: "#198753",
        iconHtml: "<i class='bi bi-check-circle'></i>",
        footer: undefined,
        template: undefined,
        backdrop: true,
        toast: false,
        target: "body",
        width: undefined,
        padding: undefined,
        color: "#198753",
        background: "#FFFFFF",
        position: "center",
        grow: false,
        animation: true,
        showClass: undefined,
        hideClass: undefined,
        customClass: {
          container: undefined,
          popup: 'p-3',
          title: 'text-uppercase',
          closeButton: undefined,
          icon: 'my-0',
          image: undefined,
          htmlContainer: undefined,
          input: undefined,
          inputLabel: undefined,
          validationMessage: undefined,
          actions: 'w-100',
          confirmButton: 'btn btn-success text-uppercase w-100',
          denyButton: undefined,
          cancelButton: undefined,
          loader: undefined,
          footer: undefined,
          timerProgressBar: undefined,
        },
        timer: undefined,
        timerProgressBar: false,
        heightAuto: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: true,
        stopKeydownPropagation: true,
        keydownListenerCapture: false,
        showConfirmButton: true,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'OK',
        denyButtonText: undefined,
        cancelButtonText: undefined,
        confirmButtonColor: undefined,
        denyButtonColor: undefined,
        cancelButtonColor: undefined,
        confirmButtonAriaLabel: undefined,
        denyButtonAriaLabel: undefined,
        cancelButtonAriaLabel: undefined,
        buttonsStyling: false,
        reverseButtons: false,
        focusConfirm: true,
        focusDeny: false,
        focusCancel: false,
        returnFocus: true,
        showCloseButton: false,
        closeButtonHtml: undefined,
        closeButtonAriaLabel: undefined,
        loaderHtml: undefined,
        showLoaderOnConfirm: false,
        showLoaderOnDeny: false,
        preConfirm: undefined,
        preDeny: undefined,
        imageUrl: undefined,
        imageWidth: undefined,
        imageHeight: undefined,
        imageAlt: undefined,
        inputLabel: undefined,
        inputPlaceholder: undefined,
        inputValue: undefined,
        inputOptions: undefined,
        inputAutoFocus: undefined,
        inputAutoTrim: undefined,
        inputAttributes: undefined,
        returnInputValueOnDeny: undefined,
        validationMessage: undefined,
        progressSteps: undefined,
        currentProgressStep: undefined,
        progressStepsDistance: undefined,
        willOpen: undefined,
        didOpen: undefined,
        didRender: undefined,
        willClose: undefined,
        didClose: undefined,
        didDestroy: undefined,
        scrollbarPadding: undefined,
      }).then(() => {
        resolve();
      });
    });
  }

  confirm(message: string): Promise<boolean> {
    return new Promise((resolve) => {
      Swal.fire({
        title: undefined,
        titleText: "CONFIMAR",
        text: message,
        html: undefined,
        icon: "question",
        iconColor: "#13CAF0",
        iconHtml: "<i class='bi bi-question-circle'></i>",
        footer: undefined,
        template: undefined,
        backdrop: true,
        toast: false,
        target: "body",
        width: undefined,
        padding: undefined,
        color: "#13CAF0",
        background: "#FFFFFF",
        position: "center",
        grow: false,
        animation: true,
        showClass: undefined,
        hideClass: undefined,
        customClass: {
          container: undefined,
          popup: 'p-3',
          title: 'text-uppercase',
          closeButton: undefined,
          icon: 'my-0',
          image: undefined,
          htmlContainer: undefined,
          input: undefined,
          inputLabel: undefined,
          validationMessage: undefined,
          actions: 'w-100 flex-row justify-content-evenly',
          confirmButton: 'btn btn-success text-uppercase mx-1',
          denyButton: undefined,
          cancelButton: 'btn btn-danger text-uppercase mx-1',
          loader: undefined,
          footer: undefined,
          timerProgressBar: undefined,
        },
        timer: undefined,
        timerProgressBar: false,
        heightAuto: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: true,
        stopKeydownPropagation: true,
        keydownListenerCapture: false,
        showConfirmButton: true,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        denyButtonText: undefined,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: undefined,
        denyButtonColor: undefined,
        cancelButtonColor: undefined,
        confirmButtonAriaLabel: undefined,
        denyButtonAriaLabel: undefined,
        cancelButtonAriaLabel: undefined,
        buttonsStyling: false,
        reverseButtons: false,
        focusConfirm: true,
        focusDeny: false,
        focusCancel: false,
        returnFocus: true,
        showCloseButton: false,
        closeButtonHtml: undefined,
        closeButtonAriaLabel: undefined,
        loaderHtml: undefined,
        showLoaderOnConfirm: false,
        showLoaderOnDeny: false,
        preConfirm: undefined,
        preDeny: undefined,
        imageUrl: undefined,
        imageWidth: undefined,
        imageHeight: undefined,
        imageAlt: undefined,
        inputLabel: undefined,
        inputPlaceholder: undefined,
        inputValue: undefined,
        inputOptions: undefined,
        inputAutoFocus: undefined,
        inputAutoTrim: undefined,
        inputAttributes: undefined,
        returnInputValueOnDeny: undefined,
        validationMessage: undefined,
        progressSteps: undefined,
        currentProgressStep: undefined,
        progressStepsDistance: undefined,
        willOpen: undefined,
        didOpen: undefined,
        didRender: undefined,
        willClose: undefined,
        didClose: undefined,
        didDestroy: undefined,
        scrollbarPadding: undefined,
      }).then((value: SweetAlertResult<any>) => {
        resolve(value.isConfirmed ? true : false);
      });
    });
  }
}
