<div class="d-flex flex-column h-100 overflow-hidden">
    <div class="row align-items-center justify-content-end border-bottom border-dark pb-2">
        <div class="col-12 my-3">
            <h1 class="m-0 fs-4">
                <i class="bi bi-person-fill-gear"></i>
                Cambiar contraseña
            </h1>
        </div>
    </div>

    <div class="flex-fill overflow-auto pt-2">
        <div class="row align-items-center mx-0">
            <div class="col-12 col-md-6 col-xl-3 mb-3 px-0 px-md-2">
                <label class="form-label">
                    Nueva contraseña
                </label>
                <input type="password" class="form-control" [(ngModel)]="password" />
            </div>
            <div class="col-12 col-md-6 col-xl-3 mb-3 px-0 px-md-2">
                <label class="form-label">
                    Confirmar nueva contraseña
                </label>
                <input type="password" class="form-control" [(ngModel)]="confirmPassword" />
            </div>
        </div>
    </div>

    <div class="row align-items-center justify-content-end border-top border-dark pt-2">
        <div class="col-auto">
            <button class="btn btn-success btn-lg" (click)="onSave()">
                <i class="bi bi-check-lg me-lg-2"></i>
                <span class="d-none d-lg-inline-block">
                    Guardar
                </span>
            </button>
        </div>
    </div>
</div>

