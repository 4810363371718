<div class="d-flex flex-column h-100 overflow-hidden">
    <div class="row align-items-center justify-content-end border-bottom border-dark pb-2">
        <div class="col-12 my-3">
            <h1 class="m-0 fs-4">
                <i class="bi bi-person-fill"></i>
                {{ user.id ? "Ver usurio" : "Nuevo usuario" }}
            </h1>
        </div>
    </div>

    <div class="flex-fill overflow-auto pt-2">
        <div class="row align-items-center mx-0">
            <div class="col-12 col-md-6 col-xl-3 mb-3 px-0 px-md-2">
                <label class="form-label">
                    Nombre
                </label>
                <input type="text" maxlength="50" class="form-control" [(ngModel)]="user.name" />
            </div>
            <div class="col-12 col-md-6 col-xl-3 mb-3 px-0 px-md-2">
                <label class="form-label">
                    Apellido
                </label>
                <input type="text" maxlength="50" class="form-control" [(ngModel)]="user.last_name" />
            </div>
            <div class="col-12 col-md-6 col-xl-3 mb-3 px-0 px-md-2">
                <label class="form-label">
                    Correo electrónico
                </label>
                <input type="email" maxlength="255" class="form-control" [(ngModel)]="user.email" />
            </div>
        </div>
    </div>

    <div class="row align-items-center justify-content-end border-top border-dark pt-2">
        <div class="col-auto">
            <a routerLink="/admin/usuarios" class="btn btn-dark btn-lg">
                <i class="bi bi-chevron-left me-lg-2"></i>
                <span class="d-none d-lg-inline-block">
                    Volver
                </span>
            </a>
        </div>
        <div class="col-auto">
            <button class="btn btn-success btn-lg" (click)="onSave()">
                <i class="bi bi-check-lg me-lg-2"></i>
                <span class="d-none d-lg-inline-block">
                    Guardar
                </span>
            </button>
        </div>
        <div class="col-auto" *ngIf="user.id && !isSameUser()">
            <button class="btn btn-danger btn-lg" (click)="onDelete()">
                <i class="bi bi-trash me-lg-2"></i>
                <span class="d-none d-lg-inline-block">
                    Eliminar
                </span>
            </button>
        </div>
    </div>
</div>
