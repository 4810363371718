<div class="container-fluid h-100">
    <div class="row h-100">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 m-auto text-center">
            <img src="../../../assets/img/Logo-cian.png" alt="EOLOS" class="img-fluid mb-5" />
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<app-loader [visible]="loading"></app-loader>
