<div class="mb-3 text-start">
    <h1 class="fs-5 text-uppercase m-0">
        <i class="bi bi-lock-fill me-2"></i>
        Restablecimiento de contraseña
    </h1>
</div>

<div class="mb-3">
    <input type="password" class="form-control" [(ngModel)]="password" />
</div>

<div class="mb-3">
    <input type="password" class="form-control" [(ngModel)]="confirmPassword" />
</div>

<div class="d-grid gap-2 mb-3">
    <button class="btn btn-primary text-uppercase" (click)="onReset()">
        Restablecer contraseña
    </button>
</div>

<hr class="my-5"/>

<a routerLink="/admin" class="btn btn-link">
    Iniciar sesión
</a>

