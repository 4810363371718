import { Component, OnDestroy, OnInit } from '@angular/core';
import { IconDefinition, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  linkedinIcon : IconDefinition = faLinkedinIn;
  twitterIcon : IconDefinition = faTwitter;

  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
