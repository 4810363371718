import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ApiResponseModel from 'src/app/models/api-response.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ApiService } from 'src/app/services/api/api.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

const _COMPONENT_NAME: string = "CONTACT_PAGE";

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit, OnDestroy {
  interests: {
    name: string;
    selected: boolean;
  }[] = [
    {
      name: "contact_center",
      selected: false,
    },
    {
      name: "omnichannel_communications_platform",
      selected: false,
    },
    {
      name: "click_2_call",
      selected: false,
    },
    {
      name: "eolos_meet",
      selected: false,
    },
    {
      name: "midleware_eolosmq",
      selected: false,
    },
    {
      name: "soft_switch",
      selected: false,
    },
    {
      name: "mobile_operators",
      selected: false,
    },
    {
      name: "smart_bed",
      selected: false,
    },
    {
      name: "health_care_statistics",
      selected: false,
    },
    {
      name: "ivr_sms",
      selected: false,
    },
  ];

  fullName: string = "";
  email: string = "";
  company: string = "";
  message: string = "";

  constructor(
    private _apiService: ApiService,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onSend(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._apiService.post<ApiResponseModel>(`/contact`, {
      full_name: this.fullName,
      email: this.email,
      company: this.company,
      message: this.message,
      interests: this.interests.filter((interest: { name: string; selected: boolean; }) => {
        return interest.selected;
      }).map((interest: { name: string; selected: boolean; }) => {
        return this._translateService.instant(interest.name);
      }),
    }).subscribe({
      next: (response: ApiResponseModel) => {
        this._alertService.success(response.message).then(() => {
          this.fullName = "";
          this.email = "";
          this.company = "";
          this.message = "";
          this.interests.forEach((interest: { name: string; selected: boolean; }) => {
            interest.selected = false;
          });
        });
        this._loaderService.stop(_COMPONENT_NAME);
      },
      error: (error: string) => {
        this._alertService.error(error);
        this._loaderService.stop(_COMPONENT_NAME);
      },
    });
  }
}
