<div class="d-flex flex-column h-100 overflow-hidden">
    <div class="row align-items-center justify-content-end border-bottom border-dark pb-2">
        <div class="col-12 my-3">
            <h1 class="m-0 fs-4">
                <i class="bi bi-file-text-fill"></i>
                Entradas de blog
            </h1>
        </div>
        <div class="col-12">
            <input type="text" class="form-control" [(ngModel)]="filter" placeholder="Buscar..." />
        </div>
    </div>

    <div class="flex-fill overflow-y-auto pt-2">
        <div class="row align-items-center mx-0">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-2 px-0" *ngFor="let blogEntry of getFilteredBlogEntries()">
                <div class="d-grid gap-2 h-100">
                    <a [routerLink]="['/admin/entrada-blog', blogEntry.id]" class="btn btn-primary d-flex flex-column justify-content-between">
                        {{ getBlogEntryTitle(blogEntry) }}
                        <br/>
                        <small>
                            {{ blogEntry.date | date:'dd-MM-yyyy' }}
                        </small>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row align-items-center justify-content-end border-top border-dark pt-2">
        <div class="col-auto">
            <a routerLink="/admin/entrada-blog" class="btn btn-secondary btn-lg">
                <i class="bi bi-plus-lg me-lg-2"></i>
                <span class="d-none d-lg-inline-block">
                    Nueva entrada de blog
                </span>
            </a>
        </div>
    </div>
</div>

