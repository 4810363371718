import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private _translateService: TranslateService,
  ) {
    this._translateService.setDefaultLang('en');
    this._translateService.use('en');
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
