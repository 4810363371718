<div class="h-100 w-100 d-flex flex-row">
    <div class="d-flex flex-column flex-fill h-100">
        <div class="d-flex flex-row bg-primary p-2">
            <img src="../../../assets/img/Logo-blanco.png" alt="" class="img-fluid" style="height: 50px;" />
        </div>
        <div class="d-flex flex-row flex-fill p-2">
            <div class="w-100">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column h-100 bg-primary p-2 justify-content-between">
        <div class="d-flex flex-column">
            <button class="btn btn-dark btn-lg mb-2" (click)="onOpenMenu()">
                <i class="bi bi-list"></i>
            </button>
            <a class="btn btn-dark btn-lg mb-2" routerLink="/admin/usuarios">
                <i class="bi bi-person-fill"></i>
            </a>
            <a class="btn btn-dark btn-lg mb-2" routerLink="/admin/entradas-blog">
                <i class="bi bi-file-text-fill"></i>
            </a>
        </div>

        <div class="d-flex flex-column">
            <a class="btn btn-dark btn-lg mb-2" routerLink="/admin/cambiar-contrasena">
                <i class="bi bi-person-fill-gear"></i>
            </a>
            <button class="btn btn-danger btn-lg" (click)="onLogout()">
                <i class="bi bi-power"></i>
            </button>
        </div>
    </div>
</div>

<ng-template #menu let-offcanvas>
	<div class="offcanvas-header bg-primary p-2">
		<h4 class="offcanvas-title text-uppercase m-0 fs-2">
            <i class="bi bi-list"></i>
            Menú
        </h4>
		<button class="btn btn-dark btn-lg" (click)="offcanvas.dismiss('Cross click')">
            <i class="bi bi-x-lg"></i>
        </button>
	</div>
	<div class="offcanvas-body p-2">
        <div class="h-100 d-flex flex-column justify-content-between">
            <div>
                <div class="d-grid gap-2 mb-2">
                    <a class="btn btn-secondary btn-lg d-flex flex-row" routerLink="/admin/usuarios" (click)="onCloseMenu()">
                        <i class="bi bi-person-fill me-2"></i>
                        <span class="flex-fill">
                            Usuarios
                        </span>
                    </a>
                </div>
                <div class="d-grid gap-2 mb-2">
                    <a class="btn btn-secondary btn-lg d-flex flex-row" routerLink="/admin/entradas-blog" (click)="onCloseMenu()">
                        <i class="bi bi-file-text-fill me-2"></i>
                        <span class="flex-fill">
                            Entradas de blog
                        </span>
                    </a>
                </div>
            </div>
            <div>
                <div class="d-grid gap-2 mb-2">
                    <a class="btn btn-secondary btn-lg d-flex flex-row" routerLink="/admin/cambiar-contrasena" (click)="onCloseMenu()">
                        <i class="bi bi-person-fill-gear me-2"></i>
                        <span class="flex-fill">
                            Cambiar contraseña
                        </span>
                    </a>
                </div>
                <div class="d-grid gap-2">
                    <button class="btn btn-outline-danger btn-lg d-flex flex-row" (click)="onLogout()">
                        <i class="bi bi-power me-2"></i>
                        <span class="flex-fill">
                            Salir
                        </span>
                    </button>
                </div>
            </div>
        </div>
	</div>
</ng-template>
