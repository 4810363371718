import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css']
})
export class InfoCardComponent implements OnInit, OnDestroy {
  @Input()
  color!: "dark" | "primary" | "secondary" | "green";
  @Input()
  description!: string; 

  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
