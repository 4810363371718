import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import ApiResponseModel from 'src/app/models/api-response.model';
import UserModel from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { UserService } from 'src/app/services/user/user.service';

const _COMPONENT_NAME: string = "ADMIN_USER_PAGE";

@Component({
  selector: 'app-admin-user-page',
  templateUrl: './admin-user-page.component.html',
  styleUrls: ['./admin-user-page.component.css']
})
export class AdminUserPageComponent implements OnInit, OnDestroy {
  user: UserModel = {
    id: 0,
    name: "",
    last_name: "",
    email: "",
  };

  constructor(
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _loaderService: LoaderService,
    private _alertService: AlertService,
    private _authService: AuthService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    let id: string | null = this._activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      this._loaderService.start(_COMPONENT_NAME);
      this._userService.getById(parseInt(id)).then((user: UserModel) => {
        this.user = user;
        this._loaderService.stop(_COMPONENT_NAME);
      }, (error: string) => {
        this._alertService.error(error);
        this._loaderService.stop(_COMPONENT_NAME);
      });
    }
  }

  ngOnDestroy(): void {
    
  }

  isSameUser(): boolean {
    return (this.user.id === this._authService.getUser()?.id);
  }

  onSave(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._userService.save(this.user).then((response: ApiResponseModel) => {
      this._alertService.success(response.message).then(() => {
        this._router.navigateByUrl("/admin/usuarios");
      });
      this._loaderService.stop(_COMPONENT_NAME);
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }

  onDelete(): void {
    this._alertService.confirm(`¿Confirma que desea eliminar el usuario ${this.user.name} ${this.user.last_name}?`).then((response: boolean) => {
      if (response) {
        this._loaderService.start(_COMPONENT_NAME);
        this._userService.delete(this.user).then((response: ApiResponseModel) => {
          this._alertService.success(response.message).then(() => {
            this._router.navigateByUrl("/admin/usuarios");
          });
          this._loaderService.stop(_COMPONENT_NAME);
        }, (error: string) => {
          this._alertService.error(error);
          this._loaderService.stop(_COMPONENT_NAME);
        });
      }
    });
  }
}
