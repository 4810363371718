<nav class="navbar navbar-expand-lg bg-dark py-0 px-5 border-0">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="../../../assets/img/Logo-blanco.png" alt="EOLOS" style="height: 30px;" />
        </a>
        <button class="navbar-toggler">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav ms-auto align-items-center">
                <li class="nav-item px-0">
                    <a class="nav-link text-white py-3 px-3" [ngClass]="{ 'active': isActive(['/']) }" routerLink="/">
                        {{ 'home' | translate }}
                    </a>
                </li>
                <li class="nav-item px-0">
                    <div ngbDropdown>
                        <button ngbDropdownToggle class="nav-link text-white py-3 px-3" 
                            [ngClass]="{ 'active': isActive(['/services/eolos-now', '/services/hardware', '/services/device-connections', '/services/smart-bed']) }">
                            {{ 'services' | translate }}
                        </button>
                        <div ngbDropdownMenu class="bg-dark border-dark dropdown-menu-start">
                            <a ngbDropdownItem class="bg-dark text-white" routerLink="/services/eolos-now">
                                Eolos Now
                            </a>
                            <a ngbDropdownItem class="bg-dark text-white" routerLink="/services/hardware">
                                Hardware
                            </a>
                            <a ngbDropdownItem class="bg-dark text-white" routerLink="/services/device-connections">
                                Device connections
                            </a>
                            <a ngbDropdownItem class="bg-dark text-white" routerLink="/services/smart-bed">
                                Smart bed
                            </a>
                        </div>
                    </div>
                </li>
                <li class="nav-item px-0">
                    <a class="nav-link text-white py-3 px-3" [ngClass]="{ 'active': isActive(['/about-us']) }" routerLink="/about-us">
                        {{ 'about_us' | translate }}
                    </a>
                </li>
                <li class="nav-item px-0">
                    <div ngbDropdown>
                        <button ngbDropdownToggle class="nav-link text-white py-3 px-3" 
                            [ngClass]="{ 'active': isActive(['/success-stories/cloud-call-center', '/success-stories/pbx-multitenant-corporate', '/success-stories/integral-telephony-solution', '/success-stories/contact-center-bot-whatsapp']) }">
                            {{ 'success_stories' | translate }}
                        </button>
                        <div ngbDropdownMenu class="bg-dark border-dark dropdown-menu-start">
                            <a ngbDropdownItem class="bg-dark text-white" routerLink="/success-stories/cloud-call-center">
                                Cloud call center
                            </a>
                            <a ngbDropdownItem class="bg-dark text-white" routerLink="/success-stories/pbx-multitenant-corporate">
                                PBX MultiTenant - Corporate
                            </a>
                            <a ngbDropdownItem class="bg-dark text-white" routerLink="/success-stories/integral-telephony-solution">
                                Integral telephony solution
                            </a>
                            <a ngbDropdownItem class="bg-dark text-white" routerLink="/success-stories/contact-center-bot-whatsapp">
                                Contact center - BOT Whatsapp
                            </a>
                        </div>
                    </div>
                </li>
                <li class="nav-item px-0">
                    <a class="nav-link text-white py-3 px-3" [ngClass]="{ 'active': isActive(['/blog']) }" routerLink="/blog">
                        {{ 'blog' | translate }}
                    </a>
                </li>
                <li class="nav-item px-0">
                    <a class="nav-link text-white py-3 px-3" [ngClass]="{ 'active': isActive(['/contact']) }" routerLink="/contact">
                        {{ 'contact' | translate }}
                    </a>
                </li>
                <li class="nav-item dropdown-no-arrow px-3" ngbDropdown display="dynamic" placement="bottom-end">
                    <button class="nav-link text-white" ngbDropdownToggle>
                        <img src="../../../assets/img/flags/{{ getCurrentLanguage()?.flag_code }}.svg" [alt]="getCurrentLanguage()?.name" 
                            style="height: 30px;" />
                    </button>
                    <div ngbDropdownMenu style="min-width: 0;">
                        <button ngbDropdownItem class="px-2" *ngFor="let language of getLanguages()" (click)="setLanguage(language)">
                            <img src="../../../assets/img/flags/{{ language.flag_code }}.svg" [alt]="language.name" style="height: 30px;" />
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
