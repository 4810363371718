import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-hardware-page',
  templateUrl: './services-hardware-page.component.html',
  styleUrls: ['./services-hardware-page.component.css']
})
export class ServicesHardwarePageComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
