<div class="d-flex flex-column h-100 overflow-hidden">
    <div class="row align-items-center justify-content-end border-bottom border-dark pb-2">
        <div class="col-12 my-3">
            <h1 class="m-0 fs-4">
                <i class="bi bi-file-text-fill"></i>
                {{ blogEntry.id ? "Ver entrada de blog" : "Nueva entrada de blog" }}
            </h1>
        </div>
    </div>

    <div class="flex-fill overflow-auto pt-2">
        <div class="row align-items-center mx-0">
            <div class="col-12 col-md-6 col-xl-3 mb-3 px-0">
                <label class="form-label">
                    Fecha
                </label>
                <input type="date" class="form-control" [(ngModel)]="blogEntry.date" />
            </div>
        </div>

        <ul ngbNav #nav="ngbNav" class="nav-tabs" activeId="es">
            <li *ngFor="let blogEntryLanguage of blogEntry.blog_entry_languages" [ngbNavItem]="blogEntryLanguage.language.code">
                <button ngbNavLink>
                    {{ blogEntryLanguage.language.name }}
                </button>
                <ng-template ngbNavContent>
                    <div class="p-2">
                        <div class="row align-items-center mx-0">
                            <div class="col-12 mb-3 px-0">
                                <label class="form-label">
                                    Título
                                </label>
                                <input type="text" maxlength="100" class="form-control" [(ngModel)]="blogEntryLanguage.title" />
                            </div>
                            <div class="col-12 mb-3 px-0">
                                <label class="form-label">
                                    Contenido
                                </label>
                                <ckeditor [(ngModel)]="blogEntryLanguage.content" [editor]="Editor" [config]="editorConfig"></ckeditor>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>

    <div class="row align-items-center justify-content-end border-top border-dark pt-2">
        <div class="col-auto">
            <a routerLink="/admin/entradas-blog" class="btn btn-dark btn-lg">
                <i class="bi bi-chevron-left me-lg-2"></i>
                <span class="d-none d-lg-inline-block">
                    Volver
                </span>
            </a>
        </div>
        <div class="col-auto">
            <button class="btn btn-success btn-lg" (click)="onSave()">
                <i class="bi bi-check-lg me-lg-2"></i>
                <span class="d-none d-lg-inline-block">
                    Guardar
                </span>
            </button>
        </div>
        <div class="col-auto" *ngIf="blogEntry.id">
            <button class="btn btn-danger btn-lg" (click)="onDelete()">
                <i class="bi bi-trash me-lg-2"></i>
                <span class="d-none d-lg-inline-block">
                    Eliminar
                </span>
            </button>
        </div>
    </div>
</div>
