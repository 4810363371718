<div class="separator"></div>

<div class="container py-5">
    <div class="row align-items-start py-3">
        <div class="col-12 col-lg-3 mb-3 mb-lg-0">
            <img src="../../../assets/img/Logo-cian.png" alt="EOLOS" style="height: 30px;" />

            <a routerLink="/quality-policies" class="d-block text-gray text-decoration-none mt-5 fs-5">
                {{ 'quality_policies' | translate }}
            </a>
        </div>
        <div class="col-12 col-lg-3 mb-3 mb-lg-0 text-gray">
            <p class="mb-3 fs-5 fw-bold">
                {{ 'united_states' | translate }}
            </p>

            <p class="m-0">
                Eolos IT CORP
                <br/>
                3663 SW 8th. St Suite 210
                <br/>
                +13054002322
                <br/>
                33132, Coral Gables, FL, USA
            </p>
        </div>
        <div class="col-12 col-lg-3 mb-3 mb-lg-0 text-gray">
            <p class="mb-3 fs-5 fw-bold">
                {{ 'argentina' | translate }}
            </p>

            <p class="m-0">
                Av Pueyrredón 538
                <br/>
                +541150323352
                <br/>
                C1190, CABA, Argentina
            </p>
        </div>
        <div class="col-12 col-lg-3 mb-3 mb-lg-0 text-center">
            <img src="../../../assets/img/ISO-logo-footer.png" alt="ISO Logo" style="height: 100px;" />

            <div class="row align-items-center justify-content-center mt-3">
                <div class="col-auto">
                    <a href="https://www.linkedin.com/company/eolosit" target="_blank" class="btn btn-dark rounded-circle">
                        <fa-icon [icon]="linkedinIcon"></fa-icon>
                    </a>
                </div>
                <div class="col-auto">
                    <a href="https://twitter.com/eolosit" target="_blank" class="btn btn-dark rounded-circle">
                        <fa-icon [icon]="twitterIcon"></fa-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
