import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

const _COMPONENT_NAME: string = "ADMIN_LOGIN_PAGE";

@Component({
  selector: 'app-admin-login-page',
  templateUrl: './admin-login-page.component.html',
  styleUrls: ['./admin-login-page.component.css']
})
export class AdminLoginPageComponent implements OnInit, OnDestroy {
  email: string = "";
  password: string = "";

  constructor(
    private _loaderService: LoaderService,
    private _authService: AuthService,
    private _alertService: AlertService,
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  onLogin(): void {
    this._loaderService.start(_COMPONENT_NAME);
    this._authService.login(this.email, this.password).then(() => {
      
    }, (error: string) => {
      this._alertService.error(error);
      this._loaderService.stop(_COMPONENT_NAME);
    });
  }
}
