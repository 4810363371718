import { Injectable } from '@angular/core';
import UserModel from 'src/app/models/user.model';
import { Subject, catchError, lastValueFrom, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import ApiResponseModel from 'src/app/models/api-response.model';
import { environment } from 'src/environments/environment';

const _UNEXPECTED_ERROR: string = "Ha ocurrido un error inesperado. Por favor, intente nuevamente.";

export interface IAuth {
  user: UserModel;
  access_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user?: UserModel = undefined;
  private token?: string = undefined;

  authChanged: Subject<void> = new Subject<void>();

  constructor(
    private _httpClient: HttpClient,
  ) {
    let userString: string | null = sessionStorage.getItem('user');
    let token: string | null = sessionStorage.getItem('token');
    if (userString && token) {
      try {
        this.user = JSON.parse(userString) as UserModel;
        this.token = token;
      }
      catch {
        this.user = undefined;
        this.token = undefined;
       
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
      }
    }
    else {
      this.user = undefined;
      this.token = undefined;
    }
  }

  private getHeaders(): { [header: string]: string | string[] } {
    return {
      "Content-Type": "application/json",
      "Accept": "application/json",
    };
  }

  private getHeadersWithToken(): { [header: string]: string | string[] } {
    return {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": this.token ?? "",
    };
  }

  private processErrorResponse(error: HttpErrorResponse): string {
    if (error.status === 400) {
      return error.error;
    }
    else {
      return _UNEXPECTED_ERROR;
    }
  }

  isLogged(): boolean {
    return (this.user !== undefined);
  }

  getUser(): UserModel | undefined {
    return this.user;
  }

  getToken(): string {
    return this.token || "";
  }

  login(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      lastValueFrom(this._httpClient.post<IAuth>(`${environment.API_URL}/admin/auth/login`, {
        email: email,
        password: password,
      }, {
        headers: this.getHeaders(),
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw this.processErrorResponse(error);
        }),
      )).then((response: IAuth) => {
        sessionStorage.setItem('user', JSON.stringify(response.user));
        sessionStorage.setItem('token', response.access_token);
        this.user = response.user;
        this.token = response.access_token;

        this.authChanged.next();

        resolve();
      }, (error: string) => {
        reject(error);
      });
    });
  }

  recover(email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      lastValueFrom(this._httpClient.post<ApiResponseModel>(`${environment.API_URL}/admin/auth/recover`, {
        email: email,
      }, {
        headers: this.getHeaders(),
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw this.processErrorResponse(error);
        }),
      )).then((response: ApiResponseModel) => {
        resolve(response.message);
      }, (error: string) => {
        reject(error);
      });
    });
  }

  reset(password: string, confirmPassword: string, token: string): Promise<string> {
    return new Promise((resolve, reject) => {
      lastValueFrom(this._httpClient.post<ApiResponseModel>(`${environment.API_URL}/admin/auth/reset/${token}`, {
        password: password,
        confirm_password: confirmPassword,
      }, {
        headers: this.getHeaders(),
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw this.processErrorResponse(error);
        }),
      )).then((response: ApiResponseModel) => {
        resolve(response.message);
      }, (error: string) => {
        reject(error);
      });
    });
  }

  logout(): void {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');

    this.user = undefined;
    this.token = undefined;

    this.authChanged.next();
  }

  changePassword(password: string, confirmPassword: string): Promise<string> {
    return new Promise((resolve, reject) => {
      lastValueFrom(this._httpClient.put<ApiResponseModel>(`${environment.API_URL}/admin/auth/change-password`, {
        password: password,
        confirm_password: confirmPassword,
      }, {
        headers: this.getHeadersWithToken(),
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          throw this.processErrorResponse(error);
        }),
      )).then((response: ApiResponseModel) => {
        resolve(response.message);
      }, (error: string) => {
        reject(error);
      });
    });
  }
}
