import { Component } from '@angular/core';

@Component({
  selector: 'app-quality-policies-page',
  templateUrl: './quality-policies-page.component.html',
  styleUrls: ['./quality-policies-page.component.css']
})
export class QualityPoliciesPageComponent {

}
