import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-eolos-now-page',
  templateUrl: './services-eolos-now-page.component.html',
  styleUrls: ['./services-eolos-now-page.component.css']
})
export class ServicesEolosNowPageComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
