import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.css']
})
export class PublicLayoutComponent implements OnInit, OnDestroy {
  loading: boolean = false;

  constructor(
    private _loaderService: LoaderService,
  ) {
    this._loaderService.loadingChanged.subscribe({
      next: (value: boolean) => {
        this.loading = value;
      },
    });
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
