import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-smart-bed-page',
  templateUrl: './services-smart-bed-page.component.html',
  styleUrls: ['./services-smart-bed-page.component.css']
})
export class ServicesSmartBedPageComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
