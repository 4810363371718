import { Component, OnDestroy, OnInit } from '@angular/core';
import { IServiceItem } from 'src/app/components/service-card/service-card.component';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.css']
})
export class IndexPageComponent implements OnInit, OnDestroy {
  eolosNowSubitems: IServiceItem[] = [
    {
      title: 'eolos_now_messaging_module_title',
      description: 'eolos_now_messaging_module_description',
      image: './assets/img/carousel-1.png',
    },
    {
      title: 'eolos_now_telephony_module_title',
      description: 'eolos_now_telephony_module_description',
      image: './assets/img/carousel-1.png',
    },
    {
      title: 'eolos_now_contact_center_module_title',
      description: 'eolos_now_contact_center_module_description',
      image: './assets/img/carousel-1.png',
    },
    {
      title: 'eolos_now_integrations_module_title',
      description: 'eolos_now_integrations_module_description',
      image: './assets/img/carousel-1.png',
    },
  ];
  hardwareSubitems: IServiceItem[] = [
    {
      title: 'hardware_intercom_title',
      description: 'hardware_intercom_description',
      image: './assets/img/carousel-1.png',
    },
  ];

  deviceConnectionSubitems: IServiceItem[] = [
    {
      title: 'device_connection_sustainable_buildings_title',
      description: 'device_connection_sustainable_buildings_description',
      image: './assets/img/carousel-1.png',
    },
  ];

  smartBedSubitems: IServiceItem[] = [
    {
      title: 'smart_bed_simple_connection_title',
      description: 'smart_bed_simple_connection_description',
      image: './assets/img/carousel-1.png',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }
}
